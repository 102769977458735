* {
	font-family: "Poppins", sans-serif;
}

body {
}

/* .react-player {
  position: relative;
  left: 0;
  top: 0;
  transform: none;
} */

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: grey;
	border-radius: 10px;
}
td {
	color: white;
}

CardData {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding: 16px;
	gap: 10px;

	width: 328px;
	height: 300px;

	background: #ffffff;
	/* ambient */

	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;
}

@media screen and (min-width: 880px) {
}
