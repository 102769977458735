/* .container {
	background-color: aliceblue;
} */

h4 {
	color: #e34607;
	line-height: 34px;
}

h2 {
	color: #e34607;
	font-size: 1.5rem;
	line-height: 34px;
}
.playerContainer {
	gap: 0;
	height: 40vh;
	margin-bottom: 4rem;
}

.playerContainer__image {
	height: 100%;
}

.playerContainer__image img {
	height: 100%;
	width: auto;
}

.NavigationPanel {
	display: block;
	width: 100%;
	gap: 3rem;
	margin: 0 auto;
	margin: 6rem 0 1.5rem 0;
}

.icon {
	margin: 0 1.5rem;
	color: grey;
	scale: 1.5;
	cursor: "pointer";
	text-decoration: "none !important";
}

Accordion.Header {
	background-color: rgb(60, 43, 43);
}

.number {
	color: #e3450756;
}

.accordian__content {
	background-color: "white";
	height: 400px;
	overflow: scroll;
	padding-top: 18px;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.accordian__content::-webkit-scrollbar {
	display: none;
}

.accordian__search {
	width: 100%;
	padding: 1rem 1.5rem;
	outline: none;
	border-radius: 5px;
	border: 1px grey solid;
	margin: 0.4rem auto 0;
}

@media only screen and (max-width: 600px) {
	.playerContainer {
		height: 25vh;
	}
	.playerContainer__image img {
		border-radius: 20px;
	}

	.icon {
		scale: 1.125;
		margin: 0 1rem;
	}

	h2 {
		font-size: 18px;
		line-height: normal;
		text-align: left;
		width: 90%;
		margin: 0 auto 1rem;
		font-weight: 600;
	}

	p {
		text-align: left;
		width: 90%;
		margin: 0 auto 1rem;
	}
	h4 {
		font-weight: 600;
		font-size: 16px;
		line-height: normal;
	}
}
